var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.fullScreenLoading,
          expression: "fullScreenLoading",
        },
      ],
      staticClass: "manager-container module-main-body",
    },
    [
      _c(
        "el-row",
        { staticClass: "module-content-top-bar" },
        [
          _c("el-col", { attrs: { md: 24, lg: 10 } }, [
            _c("h1", { staticClass: "top-bar-title" }, [
              _vm._v(_vm._s(_vm.contentTitle)),
            ]),
          ]),
          _c("el-col", { attrs: { md: 24, lg: 14 } }, [
            _c(
              "div",
              { staticClass: "button-bar" },
              [
                _vm.hasSysAdminPermission
                  ? _c(
                      "el-dropdown",
                      { on: { command: _vm.handleMore } },
                      [
                        _c("span", { staticClass: "primary-button" }, [
                          _vm._v("More Actions"),
                        ]),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "invite_company" } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("inviteCompany")) + " "
                                ),
                              ]
                            ),
                            _vm.currentCompany.auditStatus === 1
                              ? [
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "agree" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("agreeUpdate")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "reject" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("rejectUpdate")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            _vm.currentCompany.active === 2
                              ? [
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      attrs: {
                                        command: "active_reject",
                                        disabled: !_vm.currentCompany.id,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("refuseRegister")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      attrs: {
                                        command: "active_pass",
                                        disabled: !_vm.currentCompany.id,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("acceptRegister")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  command: "en_disabled",
                                  disabled: !_vm.currentCompany.id,
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.currentCompany.enable
                                        ? _vm.$t("lock")
                                        : _vm.$t("unlock")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.hasPermission(_vm.$permissionFunctionCode.INVITE_USER)
                  ? _c(
                      "span",
                      {
                        staticClass: "primary-button",
                        on: { click: _vm.inviteUser },
                      },
                      [_vm._v(" Add User ")]
                    )
                  : _vm._e(),
                _vm.hasSysAdminPermission
                  ? _c(
                      "span",
                      {
                        staticClass: "primary-button",
                        on: { click: _vm.handleUpdateHistory },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("updateHistory")) + " ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      !_vm.fullScreenLoading
        ? _c(
            "el-row",
            {
              staticClass: "div-main-body-left-right module-content-main-view",
            },
            [
              _c(
                "el-col",
                {
                  staticClass: "left",
                  attrs: {
                    sm: _vm.hasSysAdminPermission ? 10 : 0,
                    md: _vm.hasSysAdminPermission ? 8 : 0,
                    lg: _vm.hasSysAdminPermission ? 5 : 0,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _c("base-block-title", {
                        attrs: {
                          title: _vm.$t("company"),
                          "sub-title": _vm.companyList.length,
                        },
                      }),
                      _c(
                        "div",
                        [
                          _vm.hasSysAdminPermission
                            ? _c(
                                "el-dropdown",
                                { on: { command: _vm.handleMore } },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          type: "text",
                                          icon: "el-icon-more",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: { command: "invite_company" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("inviteCompany")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _vm.currentCompany.auditStatus === 1
                                        ? [
                                            _c(
                                              "el-dropdown-item",
                                              { attrs: { command: "agree" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("agreeUpdate")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-dropdown-item",
                                              { attrs: { command: "reject" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("rejectUpdate")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                      _vm.currentCompany.active === 2
                                        ? [
                                            _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: {
                                                  command: "active_reject",
                                                  disabled:
                                                    !_vm.currentCompany.id,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("refuseRegister")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: {
                                                  command: "active_pass",
                                                  disabled:
                                                    !_vm.currentCompany.id,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("acceptRegister")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: {
                                            command: "en_disabled",
                                            disabled: !_vm.currentCompany.id,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.currentCompany.enable
                                                  ? _vm.$t("lock")
                                                  : _vm.$t("unlock")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-divider", { staticClass: "el-divider" }),
                  _c(
                    "div",
                    { staticClass: "search-bar" },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "round-input",
                          attrs: { placeholder: "Search Company Name" },
                          nativeOn: {
                            keypress: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.onHandleSearch.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.searchKey,
                            callback: function ($$v) {
                              _vm.searchKey = $$v
                            },
                            expression: "searchKey",
                          },
                        },
                        [
                          !_vm.toggleIcon
                            ? _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  type: "primary",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.onHandleSearch },
                                slot: "append",
                              })
                            : _vm._e(),
                          _vm.toggleIcon
                            ? _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  type: "primary",
                                  icon: "el-icon-close",
                                },
                                on: { click: _vm.onHandlClearSearch },
                                slot: "append",
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "content" },
                    _vm._l(_vm.companyList, function (item) {
                      return _c("company-item-new", {
                        key: item.id,
                        attrs: {
                          company: item,
                          currentCompany: _vm.currentCompany,
                          logoSrc: item.logoSrc,
                          showStatusTage: true,
                          active: item.id === _vm.currentCompany.id,
                        },
                        on: {
                          "update:logoSrc": function ($event) {
                            return _vm.$set(item, "logoSrc", $event)
                          },
                          "update:logo-src": function ($event) {
                            return _vm.$set(item, "logoSrc", $event)
                          },
                        },
                        nativeOn: {
                          click: function ($event) {
                            _vm.currentCompany = item
                          },
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.pageVisible && _vm.currentCompany.id
                ? _c(
                    "el-col",
                    {
                      staticClass: "right",
                      attrs: {
                        sm: _vm.hasSysAdminPermission ? 14 : 24,
                        md: _vm.hasSysAdminPermission ? 16 : 24,
                        lg: _vm.hasSysAdminPermission ? 19 : 24,
                      },
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "company-logo-name",
                          attrs: {
                            xs: 24,
                            sm: 8,
                            md: 6,
                            lg: 4,
                            type: "flex",
                            align: "middle",
                          },
                        },
                        [
                          _vm.currentCompany.logoSrc &&
                          _vm.currentCompany.logoSrc !== ""
                            ? _c("img", {
                                attrs: {
                                  height: "100px",
                                  width: "100px",
                                  src: _vm.currentCompany.logoSrc,
                                  alt: "",
                                },
                              })
                            : _c("el-avatar", { attrs: { size: 100 } }, [
                                _c(
                                  "span",
                                  { staticClass: "company-info-avatar" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.currentCompany
                                          ? _vm.getAvatarName(
                                              _vm.currentCompany.companyName
                                            )
                                          : ""
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 16, md: 18, lg: 20 } },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "company-info",
                              attrs: { xs: 24, sm: 24, md: 16, lg: 18 },
                            },
                            [
                              _c(
                                "el-row",
                                { staticClass: "company-info-detail" },
                                [
                                  _c(
                                    "el-row",
                                    {
                                      staticClass: "sub-title-bar",
                                      attrs: {
                                        type: "flex",
                                        justify: "space-between",
                                        align: "middle",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: _vm.hasSysAdminPermission
                                              ? 16
                                              : 10,
                                            md: _vm.hasSysAdminPermission
                                              ? 16
                                              : 10,
                                            lg: _vm.hasSysAdminPermission
                                              ? 16
                                              : 10,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "sub-title-text",
                                                },
                                                [_vm._v("Company Overview")]
                                              ),
                                              _vm.currentCompany.auditStatus ===
                                                1 &&
                                              _vm.hasPermission(
                                                _vm.$permissionFunctionCode.EDIT
                                              )
                                                ? _c(
                                                    "el-tag",
                                                    {
                                                      staticClass:
                                                        "botton-bar-tag",
                                                      staticStyle: {
                                                        "margin-left": "10px",
                                                      },
                                                      attrs: {
                                                        type: "warning",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            this.$t(
                                                              "updateAuditing"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: _vm.hasSysAdminPermission
                                              ? 8
                                              : 14,
                                            md: _vm.hasSysAdminPermission
                                              ? 8
                                              : 14,
                                            lg: _vm.hasSysAdminPermission
                                              ? 8
                                              : 14,
                                          },
                                        },
                                        [
                                          _vm.hasPermission(
                                            _vm.$permissionFunctionCode.EDIT
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "second-button",
                                                  staticStyle: {
                                                    float: "right",
                                                  },
                                                  on: {
                                                    click: _vm.openAttachments,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        this.$t("Attachment")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.hasPermission(
                                            _vm.$permissionFunctionCode.EDIT
                                          ) && !_vm.hasSysAdminPermission
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "primary-button",
                                                  staticStyle: {
                                                    float: "right",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editCompany(
                                                        "basic"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(this.$t("edit")) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.hasPermission(
                                            _vm.$permissionFunctionCode.EDIT
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "second-button",
                                                  staticStyle: {
                                                    float: "right",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editCompany(
                                                        "settings"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Settings ")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._l(_vm.overviewFields, function (key) {
                                    return _c(
                                      "el-col",
                                      {
                                        key: key,
                                        staticClass: "margin-5-0",
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c("h4", [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t(key)) + ": "
                                          ),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                !!_vm.currentCompany[key]
                                                  ? _vm.currentCompany[key]
                                                  : " - "
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    )
                                  }),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "margin-top-10",
                                      attrs: { sm: 24, md: 24 },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "sub-title-text" },
                                        [_vm._v("Company Address")]
                                      ),
                                    ]
                                  ),
                                  _vm._l(_vm.addressFields, function (key) {
                                    return _c(
                                      "el-col",
                                      {
                                        key: key,
                                        staticClass: "margin-5-0",
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c("h4", [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t(key)) + ": "
                                          ),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                !!_vm.currentCompany[key]
                                                  ? _vm.currentCompany[key]
                                                  : " - "
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    )
                                  }),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "margin-top-10",
                                      attrs: { sm: 24, md: 24 },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "sub-title-text" },
                                        [_vm._v("API Information")]
                                      ),
                                    ]
                                  ),
                                  _vm._l(_vm.apiFields, function (key) {
                                    return _c(
                                      "el-col",
                                      {
                                        key: key,
                                        staticClass: "margin-5-0",
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c("h4", [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t(key)) + ": "
                                          ),
                                        ]),
                                        key === "publicKey"
                                          ? _c(
                                              "p",
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass:
                                                      "custom-tooltip",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        _vm.currentCompany
                                                          .publicKey,
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.truncatedPublicKey
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : key === "apiKey"
                                          ? _c(
                                              "p",
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass:
                                                      "custom-tooltip",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        _vm.currentCompany
                                                          .apiKey,
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.truncatedApiKey
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    !!_vm.currentCompany[key]
                                                      ? _vm.currentCompany[key]
                                                      : " - "
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                      ]
                                    )
                                  }),
                                  _vm.currentCompany?.bdnEmailTitle &&
                                  _vm.currentCompany?.bdnEmailTitle != ""
                                    ? _c(
                                        "el-col",
                                        { attrs: { sm: 24, md: 24 } },
                                        [
                                          _c("h4", [
                                            _vm._v(
                                              _vm._s(_vm.$t("bdnEmailTitle")) +
                                                ":"
                                            ),
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.currentCompany
                                                    ?.bdnEmailTitle ?? " - "
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "bank-info",
                              attrs: { xs: 24, sm: 24, md: 8, lg: 6 },
                            },
                            [
                              _c(
                                "el-row",
                                {
                                  staticClass: "sub-title-bar",
                                  attrs: {
                                    type: "flex",
                                    justify: "space-between",
                                    align: "middle",
                                  },
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: _vm.hasSysAdminPermission ? 24 : 12,
                                        md: _vm.hasSysAdminPermission ? 24 : 12,
                                        lg: _vm.hasSysAdminPermission ? 24 : 12,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "sub-title-text" },
                                        [_vm._v("Bank Information")]
                                      ),
                                    ]
                                  ),
                                  !_vm.hasSysAdminPermission
                                    ? _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 12,
                                            md: 12,
                                            lg: 12,
                                          },
                                        },
                                        [
                                          _vm.hasPermission(
                                            _vm.$permissionFunctionCode.EDIT
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "primary-button",
                                                  staticStyle: {
                                                    float: "right",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editCompany(
                                                        "bank"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(this.$t("edit")) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._l(_vm.bankFields, function (key, index) {
                                return _c(
                                  "span",
                                  { key: key, staticClass: "bank-info-detail" },
                                  [
                                    _c("h4", [
                                      _vm._v(" " + _vm._s(_vm.$t(key)) + ": "),
                                    ]),
                                    _vm.hasSysAdminPermission
                                      ? _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.activeCompanyBankInfo &&
                                                  !!_vm.activeCompanyBankInfo[
                                                    key.slice(5)
                                                  ]
                                                  ? _vm.activeCompanyBankInfo[
                                                      key.slice(5)
                                                    ]
                                                  : " - "
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.companyDefaultBank &&
                                              !!_vm.companyDefaultBank[
                                                key.slice(5)
                                              ]
                                              ? _vm.companyDefaultBank[
                                                  key.slice(5)
                                                ]
                                              : " - "
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "users", attrs: { lg: 24 } },
                        _vm._l(_vm.users, function (user) {
                          return _c("user-card", {
                            key: user.id,
                            attrs: { user: user, company: _vm.currentCompany },
                            on: { "handle-success": _vm.getUserListByCompany },
                          })
                        }),
                        1
                      ),
                      !_vm.currentCompany.id ? _c("base-no-data") : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.editCompanyVisible
        ? _c("update-company", {
            attrs: {
              infoType: _vm.editCompanytype,
              title: _vm.editCompanyTitle,
              "original-value": _vm.currentCompany,
            },
            on: {
              closed: function ($event) {
                _vm.editCompanyVisible = false
              },
              closeDialog: _vm.closeDialog,
              "submit-success": _vm.getCompanyInfoById,
            },
          })
        : _vm._e(),
      _vm.inviteVisible
        ? _c("invite-member", {
            attrs: { "company-id": _vm.currentCompany.id },
            on: {
              closed: function ($event) {
                _vm.inviteVisible = false
              },
            },
          })
        : _vm._e(),
      _vm.updateHistoryVisible
        ? _c("update-history", {
            attrs: { company: _vm.currentCompany },
            on: {
              closed: function ($event) {
                _vm.updateHistoryVisible = false
              },
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.attachmentsVisible,
            "close-on-click-modal": false,
            top: "12vh",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.attachmentsVisible = $event
            },
            close: _vm.closeAttachments,
          },
        },
        [
          _c("div", { staticClass: "dialog-container" }, [
            _c("div", { staticClass: "top-bar" }, [
              _c("h1", [_vm._v("Attachment")]),
            ]),
            _vm.fileList.length > 0
              ? _c(
                  "div",
                  { staticClass: "attachments-container" },
                  _vm._l(_vm.fileList, function (item) {
                    return _c(
                      "div",
                      { key: item.id, staticClass: "attachment-item" },
                      [
                        _c("span", { staticClass: "attachment-name" }, [
                          _vm._v(_vm._s(item.originalName || item.name)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "button-group" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  round: "",
                                  plain: "",
                                  size: "small",
                                  type: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.previewFileMixin(item.id)
                                  },
                                },
                              },
                              [_vm._v("View")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "no-data" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/no_data.png"),
                      alt: "",
                    },
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("noDataAlt")))]),
                ]),
            _c(
              "span",
              { staticClass: "dialog-footer flex-end" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", round: "" },
                    on: { click: _vm.closeAttachments },
                  },
                  [_vm._v(" Close ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }