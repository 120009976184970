<template>
  <div style="height: 100%;">
    <div class="div-contract-list-main" v-if="view === TERM_VIEW.MAIN_TERM">
      <div class="div-contract-list-main-view-title">
        <h1>Term Contract Management</h1>
      </div>
      <div class="div-contract-list-main-view-top">
          <div>
            <el-tabs v-model="tabActive" @tab-click="getContractList(pagination.pageSize, 0)">
              <el-tab-pane
                v-for="(item, index) in TERM_CONTRACT_TAB_LIST"
                :key="index"
                :label="item.label"
                :name="item.name"
              />
            </el-tabs>
          </div>
        <div class="flex-row">
          <div>
            <el-select style="width:100%;" v-model="filterType" @change="getContractList(50,0)">
              <el-option
                v-for="item in termContractTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div v-if="hasPermission($permissionFunctionCode.ADD)">
            <el-dropdown class="primary-button" trigger="click" @command="onAddNew">
              <span>
                Add Contract<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="TERM_CONTRACT_TYPE.DELIVERED">Delivered</el-dropdown-item>
                <el-dropdown-item :command="TERM_CONTRACT_TYPE.EX_WHARF">Ex-Wharf</el-dropdown-item>
                <el-dropdown-item :command="TERM_CONTRACT_TYPE.COA">Contract of Affreightment</el-dropdown-item>
                <el-dropdown-item :command="TERM_CONTRACT_TYPE.TC">Time Charter</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div style="height: 100%">
        <div class="table-block">
          <el-table
            ref="refTable"
            v-loading="tableLoading"
            :data="contractListTableData"
            height="100%"
            row-key='id'
            :expand-row-keys="expandedRowsKey"
            @expand-change="handleChange"
            :border="true"
            :header-cell-style="{
              background: '#F4F6FA',
              alignSelf: 'center',
              textAlign: 'left',
              fontSize: '13px'
            }"
            :cell-style="{
              alignSelf: 'center',
              textAlign: 'left',
              padding: '5px',
              fontSize: '12px'
            }"
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <child-table
                  ref="childTable"
                  :termContractData="props.row"
                  @doLayout="doLayout"
                />
              </template>
            </el-table-column>
            <el-table-column min-width="180px">
              <template slot="header">
                <span>Contract Type</span>
              </template>
              <template v-slot="scope">
                <p>{{capitalizeFirstLetter(scope.row.termContractType, '_')}}</p>

              </template>
            </el-table-column>
            <el-table-column label="Trade Type" min-width="100px">
              <template v-slot="scope">
                <div>
                  {{scope.row.sellerSysOrganizationId === currentCompany.id ? 'Sales' : 'Purchase'}}
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="200px">
              <template slot="header">
                <span>Company Name</span>
              </template>
              <template v-slot="scope">
                <p>{{(scope.row.buyerSysOrganizationId === currentCompany.id) ? scope.row.sellerCompanyName : scope.row.buyerCompanyName}}</p>
              </template>
            </el-table-column>
            <el-table-column min-width="200px">
              <template slot="header">
                <span>Ref No.</span>
              </template>
              <template v-slot="scope" >
                <p v-if="(scope.row.sellerSysOrganizationId === currentCompany.id&& scope.row.sellerReferenceNo) || (scope.row.sellerSysOrganizationId !== currentCompany.id && scope.row.buyerReferenceNo)">{{(scope.row.sellerSysOrganizationId === currentCompany.id) ? scope.row.sellerReferenceNo: scope.row.buyerReferenceNo}}</p>
                <p v-else> - </p>
              </template>
            </el-table-column>
            <el-table-column min-width="150px">
              <template slot="header">
                <span>Contract Number</span>
              </template>
              <template v-slot="scope">
                <p>{{scope.row.contractNo}}</p>
              </template>
            </el-table-column>
            <el-table-column min-width="200px">
              <template slot="header">
                <span>Date Range</span>
              </template>
              <template v-slot="scope">
                <p>{{`${$moment(scope.row.contractStartDate).format('DD-MM-YYYY')} - ${$moment(scope.row.contractEndDate).format('DD-MM-YYYY')}`}} </p>
              </template>
            </el-table-column>
            <el-table-column min-width="150px">
              <template slot="header">
                <span>Total Nos. of Orders</span>
              </template>
              <template v-slot="scope">
                <p>{{scope.row.orderCount}}</p>
              </template>
            </el-table-column>
            <el-table-column width="100px">
              <template slot="header">
              </template>
              <template v-slot="scope">
                <!-- <div class="flex-between-row">
                  <el-button  @click={editRow(scope.row)} size="small" icon="el-icon-edit" circle></el-button>
                  <el-popconfirm
                    placement="left-start"
                    confirm-button-text='Delete'
                    cancel-button-text='No, Thanks'
                    icon="el-icon-info"
                    icon-color="red"
                    title="Are you sure to delete this?"
                    @confirm="deleteRow(scope.row)"
                  >
                    <el-button slot="reference" size="small" icon="el-icon-delete" circle></el-button>
                  </el-popconfirm>
                </div> -->
                <dropdown-action-menu
                  :actionList="hasPermission($permissionFunctionCode.DELETE) ? actionList : actionList.filter(item => item.key === 'view')"
                  :propsData="scope.row"
                  @handleDropdownMenuClick="handleTermDropdownMenuClick"
                />
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination-container">
          <el-pagination
            background
            @size-change="handlePageSizeChange"
            @current-change="handlePageChange"
            :page-size.sync="pagination.pageSize"
            :page-sizes="[5, 10, 20, 50, 100]"
            :current-page="pagination.pageNumber"
            layout="total, sizes, prev, pager, next"
            :total="pagination.total" />
        </div>
      </div>
    </div>
    <term-contract-form
      v-if="view !== TERM_VIEW.MAIN_TERM"
      :view="view"
      :formId="formId"
      :termContractType="termContractType"
      @handleCloseForm="handleCloseForm"
    />
    <dialog-blockchain-info  @onClose="handleCloseBlockchainInfo" v-if="blockchainInfoVisible" porpsData="blockchainData"/>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { TERM_VIEW, TERM_CONTRACT_TAB_LIST, TERM_CONTRACT_TYPE } from '@/constants.js'
import { capitalizeFirstLetter } from '@/utils/index.js'
import ChildTable from './components/ChildTable.vue'
import TermContractForm from './components/TermContractForm'
import DropdownActionMenu from '@/components/DropdownActionMenu'
import DialogBlockchainInfo from '@/components/DialogBlockchainInfo'
export default {
  name: 'TermContract',
  components: { ChildTable, TermContractForm, DropdownActionMenu, DialogBlockchainInfo },
  data () {
    const actionList = [
      { key: 'view', label: 'View', icon: 'el-icon-view' },
      { key: 'delete', label: 'Delete', icon: 'el-icon-delete' }
      // { key: 'viewBlockchainInfo', label: 'Blockchain Info', icon: 'el-icon-warning-outline' }
    ]
    return {
      // currentCompany: this.$store.state.currentCompany,
      TERM_VIEW,
      TERM_CONTRACT_TYPE,
      TERM_CONTRACT_TAB_LIST,
      view: TERM_VIEW.MAIN_TERM,
      termContractType: null,
      tabActive: 'ONGOING',
      contractListTableData: [],
      expandedRowsKey: [],
      pagination: {
        pageSize: 50,
        pageNumber: 1,
        total: 0
      },
      formId: null,
      filterType: null,
      actionList,
      blockchainData: null,
      blockchainInfoVisible: false
    }
  },
  created () {
    this.getContractList(this.pagination.pageSize, 0)
    this.setCurrentCompanyUserList()
  },

  computed: {
    ...mapState(['currentCompany']),
    termContractTypeOptions () {
      const types = Object.keys(TERM_CONTRACT_TYPE).map(key => ({
        value: TERM_CONTRACT_TYPE[key],
        label: capitalizeFirstLetter(TERM_CONTRACT_TYPE[key], '_')
      }))
      return [{
        value: '',
        label: 'All Term Contract Type'
      }, ...types]
    }
  },
  watch: {
    'currentCompany.id': {
      handler (value) {
        if (value) {
          setTimeout(() => {
            this.getContractList(this.pagination.pageSize, 0)
          }, 1000)
        }
      },
      immediate: false
    }
  },
  methods: {
    capitalizeFirstLetter,
    setCurrentCompanyUserList () {
      this.$store.dispatch('getCurrentCompanyUserList', {orgId: this.currentCompany.id})
    },
    deleteSingeTermContract (id) {
      return new Promise(resolve => {
        this.$request.delete(`${this.$apis.termContract}/${id}`).then(res => {
          if (res?.code === 1000) {
            resolve(res.data)
          }
        })
      })
    },
    onAddNew (termType) {
      this.view = TERM_VIEW.CREATE_TERM
      this.termContractType = termType
    },
    editRow (row, rowIndex) {
      this.view = TERM_VIEW.EDIT_TERM
      this.formId = row.id
      this.termContractType = row.termContractType
    },
    deleteRow (row, rowIndex) {
      this.formId = row.id
      this.deleteSingeTermContract(this.formId).then(res => {
        (res.code === 1000) && this.$message({
          type: 'success',
          message: 'Delete completed'
        })
        this.getContractList(this.pagination.pageSize, this.pagination.pageNumber - 1)
      })
    },
    handleTermDropdownMenuClick (action, data) {
      switch (action) {
        case 'delete':
          this.deleteRow(data)
          break
        case 'view':
          this.editRow(data)
          break
        case 'viewBlockchainInfo':
          this.blockchainInfoVisible = true
          this.blockchainData = data
          break
        default:
          break
      }
    },
    handleCloseForm (action) {
      this.view = TERM_VIEW.MAIN_TERM
      if (action === 'Edit' || action === 'Create') {
        this.getContractList(this.pagination.pageSize, 0)
      }
    },
    handleChange (selection, expandedRows) {
      if (expandedRows.length === 0) {
        this.expandedRowsKey = []
      } else if (expandedRows.length > 1) {
        this.expandedRowsKey = [selection.id]
      } else {
        this.expandedRowsKey.push(selection.id)
      }
    },
    doLayout () {
      this.$nextTick(() => {
        this.$refs.refTable.doLayout()
      })
    },
    reqGetTermContracts (pageSize, pageNumber) {
      const today = new Date().toISOString()
      let filter = ''
      if (this.tabActive === 'ONGOING') {
        filter = `&filter= contractEndDate >: '${today}'`
      } else {
        filter = `&filter= contractEndDate < '${today}'`
      }
      if (this.filterType && this.filterType !== '') {
        filter = `${filter} and termContractType:'${this.filterType}'`
      }
      return this.$request.get({
        url: `${this.$apis.termContract}?pageSize=${pageSize}&pageNumber=${pageNumber}${filter}&sort=updatedAt DESC`
      })
    },
    getContractList (pageSize, pageNumber) {
      this.tableLoading = true
      this.reqGetTermContracts(pageSize, pageNumber).then(res => {
        if (res?.code === 1000) {
          this.contractListTableData = res.data.content
          this.pagination.total = res.data.totalElements
          this.tableLoading = false
        }
      })
    },
    handlePageChange (pageNumber) {
      if (this.pagination.pageNumber !== pageNumber) {
        this.pagination.pageNumber = pageNumber
        this.getContractList(this.pagination.pageSize, this.pagination.pageNumber - 1)
      }
    },
    handlePageSizeChange (pageSize) {
      this.getContractList(pageSize, 0)
    },
    handleCloseBlockchainInfo () {
      this.blockchainInfoVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "./index";
</style>
