var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _vm.view === _vm.TERM_VIEW.MAIN_TERM
        ? _c("div", { staticClass: "div-contract-list-main" }, [
            _vm._m(0),
            _c("div", { staticClass: "div-contract-list-main-view-top" }, [
              _c(
                "div",
                [
                  _c(
                    "el-tabs",
                    {
                      on: {
                        "tab-click": function ($event) {
                          return _vm.getContractList(_vm.pagination.pageSize, 0)
                        },
                      },
                      model: {
                        value: _vm.tabActive,
                        callback: function ($$v) {
                          _vm.tabActive = $$v
                        },
                        expression: "tabActive",
                      },
                    },
                    _vm._l(_vm.TERM_CONTRACT_TAB_LIST, function (item, index) {
                      return _c("el-tab-pane", {
                        key: index,
                        attrs: { label: item.label, name: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "flex-row" }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        on: {
                          change: function ($event) {
                            return _vm.getContractList(50, 0)
                          },
                        },
                        model: {
                          value: _vm.filterType,
                          callback: function ($$v) {
                            _vm.filterType = $$v
                          },
                          expression: "filterType",
                        },
                      },
                      _vm._l(_vm.termContractTypeOptions, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm.hasPermission(_vm.$permissionFunctionCode.ADD)
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-dropdown",
                          {
                            staticClass: "primary-button",
                            attrs: { trigger: "click" },
                            on: { command: _vm.onAddNew },
                          },
                          [
                            _c("span", [
                              _vm._v(" Add Contract"),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      command: _vm.TERM_CONTRACT_TYPE.DELIVERED,
                                    },
                                  },
                                  [_vm._v("Delivered")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      command: _vm.TERM_CONTRACT_TYPE.EX_WHARF,
                                    },
                                  },
                                  [_vm._v("Ex-Wharf")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      command: _vm.TERM_CONTRACT_TYPE.COA,
                                    },
                                  },
                                  [_vm._v("Contract of Affreightment")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      command: _vm.TERM_CONTRACT_TYPE.TC,
                                    },
                                  },
                                  [_vm._v("Time Charter")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticStyle: { height: "100%" } }, [
              _c(
                "div",
                { staticClass: "table-block" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.tableLoading,
                          expression: "tableLoading",
                        },
                      ],
                      ref: "refTable",
                      attrs: {
                        data: _vm.contractListTableData,
                        height: "100%",
                        "row-key": "id",
                        "expand-row-keys": _vm.expandedRowsKey,
                        border: true,
                        "header-cell-style": {
                          background: "#F4F6FA",
                          alignSelf: "center",
                          textAlign: "left",
                          fontSize: "13px",
                        },
                        "cell-style": {
                          alignSelf: "center",
                          textAlign: "left",
                          padding: "5px",
                          fontSize: "12px",
                        },
                      },
                      on: { "expand-change": _vm.handleChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "expand" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (props) {
                                return [
                                  _c("child-table", {
                                    ref: "childTable",
                                    attrs: { termContractData: props.row },
                                    on: { doLayout: _vm.doLayout },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          236235050
                        ),
                      }),
                      _c(
                        "el-table-column",
                        {
                          attrs: { "min-width": "180px" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.capitalizeFirstLetter(
                                            scope.row.termContractType,
                                            "_"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2510140700
                          ),
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c("span", [_vm._v("Contract Type")]),
                          ]),
                        ],
                        2
                      ),
                      _c("el-table-column", {
                        attrs: { label: "Trade Type", "min-width": "100px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.sellerSysOrganizationId ===
                                            _vm.currentCompany.id
                                            ? "Sales"
                                            : "Purchase"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2074551369
                        ),
                      }),
                      _c(
                        "el-table-column",
                        {
                          attrs: { "min-width": "200px" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.buyerSysOrganizationId ===
                                            _vm.currentCompany.id
                                            ? scope.row.sellerCompanyName
                                            : scope.row.buyerCompanyName
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2362681728
                          ),
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c("span", [_vm._v("Company Name")]),
                          ]),
                        ],
                        2
                      ),
                      _c(
                        "el-table-column",
                        {
                          attrs: { "min-width": "200px" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    (scope.row.sellerSysOrganizationId ===
                                      _vm.currentCompany.id &&
                                      scope.row.sellerReferenceNo) ||
                                    (scope.row.sellerSysOrganizationId !==
                                      _vm.currentCompany.id &&
                                      scope.row.buyerReferenceNo)
                                      ? _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row
                                                .sellerSysOrganizationId ===
                                                _vm.currentCompany.id
                                                ? scope.row.sellerReferenceNo
                                                : scope.row.buyerReferenceNo
                                            )
                                          ),
                                        ])
                                      : _c("p", [_vm._v(" - ")]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2631695866
                          ),
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c("span", [_vm._v("Ref No.")]),
                          ]),
                        ],
                        2
                      ),
                      _c(
                        "el-table-column",
                        {
                          attrs: { "min-width": "150px" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("p", [
                                      _vm._v(_vm._s(scope.row.contractNo)),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            327779305
                          ),
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c("span", [_vm._v("Contract Number")]),
                          ]),
                        ],
                        2
                      ),
                      _c(
                        "el-table-column",
                        {
                          attrs: { "min-width": "200px" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          `${_vm
                                            .$moment(
                                              scope.row.contractStartDate
                                            )
                                            .format("DD-MM-YYYY")} - ${_vm
                                            .$moment(scope.row.contractEndDate)
                                            .format("DD-MM-YYYY")}`
                                        ) + " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2031275955
                          ),
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c("span", [_vm._v("Date Range")]),
                          ]),
                        ],
                        2
                      ),
                      _c(
                        "el-table-column",
                        {
                          attrs: { "min-width": "150px" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("p", [
                                      _vm._v(_vm._s(scope.row.orderCount)),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3374475767
                          ),
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c("span", [_vm._v("Total Nos. of Orders")]),
                          ]),
                        ],
                        2
                      ),
                      _c(
                        "el-table-column",
                        {
                          attrs: { width: "100px" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("dropdown-action-menu", {
                                      attrs: {
                                        actionList: _vm.hasPermission(
                                          _vm.$permissionFunctionCode.DELETE
                                        )
                                          ? _vm.actionList
                                          : _vm.actionList.filter(
                                              (item) => item.key === "view"
                                            ),
                                        propsData: scope.row,
                                      },
                                      on: {
                                        handleDropdownMenuClick:
                                          _vm.handleTermDropdownMenuClick,
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2971304423
                          ),
                        },
                        [_c("template", { slot: "header" })],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination-container" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "page-size": _vm.pagination.pageSize,
                      "page-sizes": [5, 10, 20, 50, 100],
                      "current-page": _vm.pagination.pageNumber,
                      layout: "total, sizes, prev, pager, next",
                      total: _vm.pagination.total,
                    },
                    on: {
                      "size-change": _vm.handlePageSizeChange,
                      "current-change": _vm.handlePageChange,
                      "update:pageSize": function ($event) {
                        return _vm.$set(_vm.pagination, "pageSize", $event)
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(_vm.pagination, "pageSize", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm.view !== _vm.TERM_VIEW.MAIN_TERM
        ? _c("term-contract-form", {
            attrs: {
              view: _vm.view,
              formId: _vm.formId,
              termContractType: _vm.termContractType,
            },
            on: { handleCloseForm: _vm.handleCloseForm },
          })
        : _vm._e(),
      _vm.blockchainInfoVisible
        ? _c("dialog-blockchain-info", {
            attrs: { porpsData: "blockchainData" },
            on: { onClose: _vm.handleCloseBlockchainInfo },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "div-contract-list-main-view-title" }, [
      _c("h1", [_vm._v("Term Contract Management")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }